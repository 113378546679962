<template>
  <!-- 盒子 -->
  <div class="zychoice">
    <!-- 全部知识套餐 -->
    <div class="zychoiceTop">
      <div class="zychoiceTopLeft">全部知识套餐</div>
      <div class="zychoiceTopRight">
        <div class="zychoiceTopRightR">
          <div class="zychoiceTopRightRL">
            <span
              class="spann"
              @click="classClick(item)"
              v-for="(item, ina) in classList"
              :key="ina"
              :class="search.classId == item.id ? 'on' : ''"
              >{{ item.tiitle }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 课程类型 -->
    <div class="zychoiceTop">
      <div class="zychoiceTopLeft">课程类型</div>
      <div class="zychoiceTopRight">
        <div class="zychoiceTopRightR">
          <div class="zychoiceTopRightRL">
            <span
              class="spann"
              @click="typeChoose(1)"
              :class="[search.type == 1 ? 'on' : '']"
              >直播</span
            >
            <span
              class="spann"
              @click="typeChoose(2)"
              :class="[search.type == 2 ? 'on' : '']"
              >录播</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 课程 -->
    <div class="zychoiceTop">
      <div class="zychoiceTopLeft">全部课程</div>
      <div class="zychoiceTopRight">
        <div class="zychoiceTopRightR">
          <div class="zychoiceTopRightRL">
            <span
              class="spann"
              @click="kcChoose(item)"
              v-for="(item, index) in courList"
              :key="index"
              :class="[search.kcId == item.courseId ? 'on' : '']"
              >{{ item.title }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { AnswerClient } from "@/api/task/answerClient";
let answerClient = new AnswerClient();
export default {
  props: {},

  data() {
    return {
      search: {
        type: 1,
        classId: null,
        kcId: null,
      },
      classList: [],
      courList: [],
    };
  },
  created() {
    answerClient.myClassesList().then((res) => {
      if (res.code == 0) {
        this.classList = res.data;
        this.classClick(res.data[0]);
      }
    });
  },
  methods: {
    /* 项目选择 */
    classClick(item) {
      if (item) {
        this.search.classId = item.id;
        this.search.kcId = "";
        this.getKc();
      }
    },
    /* 课程类型 */
    typeChoose(item) {
      if (item) {
        this.search.type = item;
        this.search.kcId = "";
        this.getKc();
      }
    },
    /* 课程选择 */
    kcChoose(item) {
      if (item) {
        this.search.kcId = item.courseId;
      }
      this.getList();
    },
    /* 课程lieb */
    async getKc() {
      await answerClient
        .pageListApi(this.search.classId, this.search.type)
        .then((res) => {
          if (res.code == 0) {
            this.courList = res.msg;
            this.kcChoose(res.msg[0]);
            this.$forceUpdate();
          }
        });
    },
    getList() {
      this.$emit("isOk", this.search);
    },
  },
};
</script>
<style lang="less" scoped>
.zychoice {
  // width: 65%;
  width: 1200px;
  // height: 224px;
  height: auto;
  margin: auto;
  margin-top: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(216, 216, 216, 0.7);
  overflow: hidden;
  .zychoicePzion {
    width: 90%;
    margin: auto;
    height: 60px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .el-breadcrumb {
      font-size: 15px;
      cursor: pointer;
    }
  }
  .zychoiceTop {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    // height: 70px;
    height: auto;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .zychoiceTopLeft {
      width: auto;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
      cursor: pointer;
      flex-shrink: 0;
      margin: 5px 28px 15px 0px;
    }
    .zychoiceTopRight {
      width: 100%;
      height: 100%;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .zychoiceTopRightL {
        width: auto;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-top: 5px;
        color: #27323f;
        cursor: pointer;
      }
      .zychoiceTopRightLs {
        width: auto;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-top: 5px;
        color: #ff4027;
        cursor: pointer;
      }
      .zychoiceTopRightR {
        width: 93%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        .zychoiceTopRightRL {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          .spann {
            width: auto;
            height: auto;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #27323f;
            margin-top: 5px;
            margin-bottom: 10px;
            margin-right: 20px;
            cursor: pointer;
          }
          .on {
            width: auto;
            height: auto;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff4027;
            margin-top: 5px;
            margin-bottom: 10px;
            margin-right: 20px;
            cursor: pointer;
          }
        }
        .zychoiceTopRightRR {
          width: 86px;
          height: 30px;
          background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .zychoiceCen {
    width: 90%;
    height: 64px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .spana {
      width: auto;
      height: auto;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
      margin-right: 53px;
      cursor: pointer;
    }
    .spanb {
      width: auto;
      height: auto;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
      margin-right: 40px;
      cursor: pointer;
    }
    .spanbs {
      width: auto;
      height: auto;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff4027;
      margin-right: 40px;
      cursor: pointer;
    }
    .spanc {
      width: auto;
      height: auto;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
      margin-right: 30px;
      cursor: pointer;
    }
    .spancs {
      width: auto;
      height: auto;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff4027;
      margin-right: 30px;
      cursor: pointer;
    }
  }
}
</style>
